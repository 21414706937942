import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import BlogTiles from '../components/sections/blog-tiles'
import MainCTA from '../components/sections/main-cta'

const BlogIndex = () => {
  return (
    <Layout>
      <Seo title="Blog" />
      <header className="header-wave-with-subtitle" id="blogHero">
        <div className="d-flex align-items-end justify-content-center flex-wrap pt-2 pb-0 pt-md-4">
          <h1 className="text-center font-swish fst-italic title-full-width">The Underground</h1>
        </div>
      </header>
      <BlogTiles
        className="pt-0 pt-md-1 pt-lg-2 pb-4 multicolored-blog-tiles"
        title="C'mon, nerd out with us as we shed some light on all things Meati™"
        id="allBlogs"
        type="all"
      />
      <MainCTA />
    </Layout>
  )
}

export default BlogIndex
