import * as React from 'react'

const BlogCaret = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.86 31.01" className={`blog-caret ${props.className}`}>
      <polygon points="18.86 16.15 4.01 0 1.06 2.71 13.21 15.92 0 28.07 2.71 31.01 18.86 16.17 18.85 16.16 18.86 16.15" />
    </svg>
  )
}

export default BlogCaret
