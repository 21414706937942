import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import { Row } from 'react-bootstrap'

import BlogTile from './parts/blog-tile'
import ButtonLink from '../common/button-link'

const BlogTiles = (props) => {
  const blogs = useStaticQuery(graphql`
    query {
      allPosts: allWpPost(sort: {fields: date, order: DESC}) {
        nodes {
          id
          slug
          excerpt
          title
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          AnimatedFeatureImage {
            animatedFeatureImage {
              localFile {
                publicURL
              }
            }
            useInMainBlogPage
          }
        }
      }
      allStickyPosts: allWpPost(sort: {fields: date, order: DESC}, filter: {isSticky: {eq: true}}) {
        nodes {
          id
          slug
          excerpt
          title
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const blogPosts = props.type === 'featured' ? blogs.allStickyPosts : blogs.allPosts

  return (
    <section className={`blog-tiles ${props.className && props.className}`} id={props.id && props.id}>
      <Container className="container-narrow">
        { props.title
          && <h4 className="text-center mb-2">{props.title}</h4>}
        { props.subtitle
          && <p className="font-swish mb-2 subtitle text-center">{ props.subtitle }</p>}

        <Row className="g-1 g-md-2">
          {
            blogPosts.nodes.map((node, index) => <BlogTile key={`blog-${index}`} node={node} />)
          }
        </Row>
        { props.type !== 'all'
          && (
          <div className="mt-2 mt-md-3 d-flex justify-content-center">
            <ButtonLink btnText="See All" btnLink="/blog" />
          </div>
          )}
      </Container>
    </section>
  )
}

export default BlogTiles
