import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Col } from 'react-bootstrap'
import BlogCaret from '../../svg-icons/blog-caret'

const BlogTile = ({ node }) => {
  const featuredImage = {
    data: node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: node.featuredImage?.node?.alt || '',
  }

  return (
    <Col sm="6" className="blog-column">
      <Link
        to={`/blog/${node.slug}`}
        className="blog-tile p-1 p-md-2 d-block h-100 d-flex flex-column"
        data-ad-element-type="blog tile"
        data-ad-text={node.title}
        data-ad-button-id="version 1"
        data-ad-button-location="body"
      >
        <div className="d-flex flex-column">
          {
            node.AnimatedFeatureImage?.animatedFeatureImage?.localFile?.publicURL && node.AnimatedFeatureImage?.useInMainBlogPage
              && (
              <div className="mb-12 rounded-5 mb-1 blog-tile-image-wrapper d-flex align-items-center justify-content-center overflow-hidden">
                <img src={node.AnimatedFeatureImage?.animatedFeatureImage?.localFile?.publicURL} alt={node.title} className="rounded-5 blog-tile-image" style={{ maxWidth: '508px' }} />
              </div>
              )
            }
          { !node.AnimatedFeatureImage?.useInMainBlogPage
              && (
              <GatsbyImage
                image={featuredImage.data}
                alt={`${featuredImage.alt}`}
                width="400"
                className="mb-12 rounded-5 mb-1 blog-tile-image-wrapper"
                imgClassName="rounded-5 blog-tile-image"
              />
              )}
          <div className="col-md-12 d-flex align-items-stretch" style={{ flexGrow: 2 }}>
            <div>
              <h3>{node.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
            <div className="d-flex align-self-center">
              <BlogCaret />
            </div>
          </div>
        </div>
      </Link>
    </Col>
  )
}

export default BlogTile
